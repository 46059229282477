.logo-container {
  z-index: 0;
  width: 400px;
  height: 609px;
  opacity: 1;
  position: absolute;
  top: 5rem;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;
  animation: pulse 3s infinite;
  user-select: none;
  
.pulse { 
    animation: pulse 3s infinite;
}
  svg {
    width: 100%;
    height: auto;
    bottom: 0;
    transform: rotateZ(0deg) !important;
  }

  .solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    opacity: 1;
    z-index: 1;
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10;
}

@keyframes example {
  0% {
    background-color: red;
    left: 0px;
    top: 0px;
  }
  25% {
    background-color: blue;
    left: 0px;
    top: 200px;
  }
  50% {
    background-color: green;
    left: 200px;
    top: 200px;
  }
  75% {
    background-color: yellow;
    left: 200px;
    top: 0px;
  }
  100% {
    background-color: red;
    left: 0px;
    top: 0px;
  }
}

@keyframes dude {
  0% {
    background-color: red;
    left: 0px;
    top: 0px;
  }
  100% {
    background-color: cyan;
    left: 0px;
    top: 200px;
  }
}